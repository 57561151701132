<template>
    <div class="my-account">
        <div>
            <el-date-picker
                v-model="couponTime"
                type="daterange"
                @change="changeTime"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                :picker-options="pickerOptions"
                end-placeholder="结束日期">
            </el-date-picker>
        </div>
        <div class="myCoupon_table">
            <el-table :data="couponDataList">
                <el-table-column label="消费时间" prop="create_time">

                </el-table-column>
                <el-table-column label="使用金额" prop="change_num">

                </el-table-column>
                <el-table-column label="消费余额" prop="change_after_num">

                </el-table-column>
            </el-table>
        </div>
        <div class="myCoupon_page">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="page.page"
                :page-size="page.page_size"
                layout="total, prev, pager, next"
                :total="pageTotal">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {voucherBill} from '@/api/member/member'
    export default {
        name: "couponDetail",
        data() {
            return{
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6
                    },
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                couponDataList: [],
                couponTime: [],
                page:{
                    page: 1,
                    page_size: 10,
                    start_time: '',
                    end_time: ''
                },
                pageTotal: 0,
            }
        },
        mounted() {
            this.voucherBillData()
        },
        methods:{
            changeTime(time) {
                if (time) {
                    this.page.start_time = time[0]
                    this.page.end_time = time[1]
                } else {
                    this.page.start_time = ''
                    this.page.end_time = ''
                }
                this.voucherBillData()
                console.log(time)
            },
            voucherBillData() {
                voucherBill(this.page).then(res =>{
                    let data = res.data
                    this.couponDataList = data.list
                    this.pageTotal = data.count
                })
            },
            handleCurrentChange(val) {
                this.page.page = val
                this.voucherVoucherListDat()
                window.scrollTo(0, 0)
                console.log(`当前页: ${val}`)
            },
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
  .my-account {
    background: #ffffff;
    padding: 20px;
  }
</style>
